import Swiper, { Manipulation } from "swiper";
import axios from "axios";
import { wordLimit } from "./utils";

export function homeShowsCarousel() {
  // swiper
  const selector = ".home-shows-carousel";
  const container = document.querySelector(selector);
  if (!container) return;

  const homeShowsCarousel = new Swiper(selector, {
    modules: [Manipulation],
    spaceBetween: 30,
    breakpoints: {
      480: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2.5,
      },
      1024: {
        slidesPerView: 3.5,
      },
      1600: {
        slidesPerView: 4.5,
      },
    },
  });

  // tabs
  const tabsContainer = document.getElementById("home-shows-carousel-tabs");
  if (!tabsContainer) return;
  tabs = tabsContainer.querySelectorAll("a");
  if (!tabs) return;

  tabs.forEach((tab) => {
    if (tab.href.charAt(tab.href.length - 1) == "#") {
      tab.onclick = async (e) => {
        e.preventDefault();

        container.classList.remove("is-ready");

        tabs.forEach((tab) => tab.classList.remove("is-active"));
        tab.classList.add("is-active");
        const showType = tab.getAttribute("data-show-type");
        console.log(showType);
        if (!homeShowsCarousel) return;

        try {
          const res = await axios(`/wp-json/shows/get?type=${showType}`);
          if (res.status !== 200) throw "Problem getting shows";
          const slides = res.data;

          homeShowsCarousel.removeAllSlides();

          slides.forEach((slide) => {
            const slideHTML = renderSlide(slide);
            homeShowsCarousel.appendSlide(slideHTML);
          });

          homeShowsCarousel.appendSlide(endSlide(showType));

          container.classList.add("is-ready");
        } catch (e) {
          console.log(e);
          alert("Sorry, the was a problem communicating with the server");
        }
      };
    }
  });
}

function renderSlide(slide) {
  const el = document.createElement("div");
  el.className = "swiper-slide show-card";

  el.innerHTML = `
    <a href="${slide.url}" class="card-image">
      <img src="${slide.image_portrait_url}" alt="show title">
    </a>
    <div class="card-info">
      <h3 class="card-title"><span>${wordLimit(slide.title, 55)}</span></h3>
      <div class="card-footer">
        <a href="${slide.url}">Learn More</a>
        <a href="${slide.url}#bookings"><span>Book Now</span></a>
      </div>
    </div>
  `;
  return el;
}

function endSlide(showType) {
  const el = document.createElement("div");
  el.className = "swiper-slide show-card end-card";
  const link = document.createElement("a");
  link.className = "view-all-link";
  link.href = `/${showType}-shows`;
  link.innerHTML = `
    <span>All ${showType} shows</span><br />
    <span class="fa fa-long-arrow-right"></span>
  `;
  el.append(link);
  return el;
}
