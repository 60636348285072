import { isMobile } from "./utils";

export default function stickyHeader(el) {
  if (!el) return;

  let lastScrollTop = 0;
  const vertBuffer = 100;
  let scrollDirection;

  let initialScrollPosition = document.documentElement.scrollTop;
  if (initialScrollPosition > 100) {
    el.setAttribute("data-scroll-direction", "up");
  }

  window.addEventListener(
    "scroll",
    function () {
      var st = document.documentElement.scrollTop;

      const megaNav = document.getElementById("mega-nav");
      const megaNavOpen = megaNav.classList.contains("show");

      if (st > lastScrollTop) {
        if (lastScrollTop > vertBuffer) {
          if (isMobile() && megaNavOpen) {
          } else {
            scrollDirection = "down";
          }
        } else {
          scrollDirection = "";
        }
      } else {
        if (lastScrollTop > vertBuffer) {
          scrollDirection = "up";
        } else {
          scrollDirection = "";
        }
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      el.setAttribute("data-scroll-direction", scrollDirection);
    },
    false
  );
}
