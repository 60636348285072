import { clearResults, getElements as getSearchElements } from "./globalSearch";

export function navElements() {
  const topMenuHambergerBtn = document.getElementById("site-hamburger-btn");
  const megaNav = document.getElementById("mega-nav");
  const megaNavCloseBtn = document.getElementById("mega-nav-close-btn");
  const headerSearchInput = document.getElementById("global-search-input");
  const headerSearch = document.getElementById("header-search");
  const headerSearchBtns = document.querySelectorAll(".header-search-btn");
  const headerSearchCloseBtn = document.querySelector(
    ".header-search-close-btn"
  );

  return {
    topMenuHambergerBtn,
    megaNav,
    megaNavCloseBtn,
    headerSearch,
    headerSearchBtns,
    headerSearchCloseBtn,
    headerSearchInput,
  };
}

export function navInit() {
  const {
    topMenuHambergerBtn,
    megaNav,
    megaNavCloseBtn,
    headerSearch,
    headerSearchBtns,
    headerSearchCloseBtn,
    headerSearchInput,
  } = navElements();

  const { searchInput } = getSearchElements();

  if (!megaNav || !topMenuHambergerBtn || !megaNavCloseBtn) return;

  topMenuHambergerBtn.onclick = (e) => {
    e.preventDefault();
    megaNav.classList.toggle("show");
  };

  megaNavCloseBtn.onclick = (e) => {
    e.preventDefault();
    megaNav.classList.remove("show");
  };

  if (headerSearchBtns) {
    headerSearchBtns.forEach((btn) => {
      btn.onclick = (e) => {
        e.preventDefault();
        headerSearch.classList.add("show");
        setTimeout(() => {
          headerSearchInput.focus();
        }, 200);
      };
    });
  }

  headerSearchCloseBtn.onclick = (e) => {
    e.preventDefault();
    headerSearch.classList.remove("show");
    if (searchInput) searchInput.value = "";
    clearResults(true);
  };
}

export function megaNavShowing() {
  const { megaNav } = navElements();
  if (!megaNav) return false;

  if (megaNav.classList.contains("show")) return true;
}

export function megaNavHide() {
  const { megaNav } = navElements();
  if (!megaNav) return;

  megaNav.classList.remove("show");
}
