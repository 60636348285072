export function getOffset(el) {
  var _x = 0;
  var _y = 0;
  var _yB = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function elScrollTracker(el) {
  let offsetTop = getOffset(el).top;
  let browserBottom = window.scrollY + window.innerHeight;
  let elTopDelta = browserBottom - offsetTop;
  let elBottomDelta = browserBottom - offsetTop - el.clientHeight;

  let visible =
    elTopDelta >= 0 && elBottomDelta <= window.innerHeight ? true : false;

  return {
    visible,
    elTopDelta,
    elBottomDelta,
    offsetTop,
    browserBottom,
  };
}

export function isPageTemplate(template) {
  return document.body.classList.contains("page-template-" + template)
    ? true
    : false;
}

export function isShowSingleTemplate() {
  return document.body.classList.contains("show-template-default")
    ? true
    : false;
}

export function isMobile() {
  return window.innerWidth <= 768;
}

export function wordLimit(text, limit = 3) {
  let trimmedString = text.substr(0, limit);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmedString;
}
