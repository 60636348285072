import { elScrollTracker } from "./utils";

export function parallaxInit() {
  homeHeroParallax();
  defaultPageParallax();

  // get all use-parallax elements
  const els = document.querySelectorAll(".use-parallax");
  if (els.length) {
    els.forEach((el) => {
      parallaxElement(el);
    });
  }
}

function homeHeroParallax() {
  const els = document.querySelectorAll(".hero-image-wrap");
  if (!els || els.length < 1) return;

  const scrollHandler = () => {
    els.forEach((el) => {
      const depth = el.getAttribute("data-parallax-depth");
      let transY = window.scrollY * 0.08 * Number(depth);
      el.style.transform = `translateY(${transY}px)`;
    });
  };

  window.addEventListener("scroll", scrollHandler);
}

function parallaxElement(el) {
  if (!el) return;

  let reverse =
    el.getAttribute("data-parallax-reverse") == "true" ? true : false;
  // console.log("reverse = ", reverse);

  let transYDistance = Number(el.getAttribute("data-parallax-y"));

  // lessen distance for mobile
  if (window.innerWidth <= 768) {
    transYDistance = 0.6 * transYDistance;
  }

  let transY = transYDistance;

  // apply margins (if enabled)
  if (el.getAttribute("data-parallax-margin") == "true") {
    el.style.marginTop = -1 * transYDistance + "px";
    el.style.marginBottom = -1 * transYDistance + "px";
  }

  function positionEl(y) {
    let _y = reverse ? y : -1 * y;
    el.style.transform = `translateY(${_y}px)`;
  }

  positionEl(transYDistance);

  window.addEventListener("scroll", () => {
    const { visible, browserBottom, offsetTop } = elScrollTracker(el);

    if (visible) {
      let distance = window.innerHeight + el.clientHeight;
      let delta = offsetTop - browserBottom;
      let perc = Math.abs(delta) / distance;
      let percHalf = Math.abs(delta) / (distance / 2);
      // console.log("perc= ", perc.toFixed(3));

      transY =
        (1 - percHalf) *
        (perc < 0.5 ? -1 : 1) *
        transYDistance *
        (perc >= 0.5 ? 1 : -1);

      positionEl(transY);
    }
  });
}

function defaultPageParallax() {
  const headerEl = document.querySelector(".hero-image");

  if (headerEl) {
    const scrollHandler = () => {
      const depth = headerEl.getAttribute("data-parallax-depth");
      let transY = window.scrollY * 0.08 * Number(depth);
      headerEl.style.transform = `translateY(${transY}px)`;
    };

    window.addEventListener("scroll", scrollHandler);
  }
}
