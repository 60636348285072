import axios from "axios";

const searchURIBase = "/wp-json/shows/search";

function updateResultsCount(count) {
  const { resultsCount, resultsCountName } = getElements();
  if (!resultsCount || !resultsCountName) return;

  let name = "shows";
  resultsCount.innerText = count;
  if (count == 1) name = "show";

  resultsCountName.innerText = name;
}

function updateSearchSuggestion(suggestion) {
  const { searchInput, searchSuggestion } = getElements();
  if (!searchSuggestion) return;

  searchSuggestion.innerText = "";

  if (suggestion == "") return;

  const preTextEl = document.createElement("span");
  preTextEl.innerText = "Did you mean ";

  const link = document.createElement("a");
  link.href = "#";
  link.innerText = suggestion;
  link.addEventListener("click", (e) => {
    e.preventDefault();
    searchInput.value = suggestion;
    doSearch(suggestion);
  });

  searchSuggestion.append(preTextEl, link);
}

export function clearResults(hide = false) {
  const { resultsContainer, resultsList } = getElements();
  if (!resultsContainer) return;

  resultsList.innerHTML = "";
  if (hide) resultsContainer.classList.remove("show");
}

function noResults() {
  const { resultsList, resultsContainer } = getElements();
  if (!resultsList || !resultsContainer) return;

  updateResultsCount(0);
  resultsList.innerHTML = "";
  resultsContainer.classList.add("show");
}

export function getElements() {
  const headerSearch = document.getElementById("header-search");
  const searchInput = document.getElementById("global-search-input");
  const resultsContainer = document.getElementById("header-search-results");
  const resultsList = document.getElementById("header-search-results-list");
  const resultsCount = document.getElementById("header-search-results-count");
  const resultsCountName = document.getElementById(
    "header-search-results-count-name"
  );
  const searchSuggestion = document.getElementById("search-suggestion");
  return {
    searchInput,
    resultsContainer,
    resultsList,
    resultsCount,
    resultsCountName,
    headerSearch,
    searchSuggestion,
  };
}

async function doSearch(query) {
  const { resultsContainer, resultsList } = getElements();
  try {
    const res = await axios(`${searchURIBase}?s=${encodeURIComponent(query)}`);
    if (res.status !== 200) throw "Problem";

    // show / hide suggestion logic
    let hasSuggestion = res.data.suggestion === false ? false : true;
    let showSuggestion = hasSuggestion;
    if (hasSuggestion) {
      if (res.data.suggestion.toLowerCase().trim() === query) {
        showSuggestion = false;
      }
    }

    updateSearchSuggestion(showSuggestion ? res.data.suggestion.trim() : "");

    if (!res.data?.results?.length) return noResults();

    clearResults();

    updateResultsCount(res.data.results.length);

    res.data.results.forEach((result) => {
      const el = renderSearchResult(result);
      resultsList.append(el);
    });

    resultsContainer.classList.add("show");
  } catch (e) {
    console.log(e);
    alert("Sorry, the was a problem communicating with the server");
  }
}

export function globalSearchInit() {
  const { searchInput, resultsContainer, resultsList } = getElements();
  if (!searchInput || !resultsContainer || !resultsList) return;

  searchInput.addEventListener("keyup", async ({ target: input }) => {
    if (input.value.length < 3) {
      clearResults(true);
      return;
    }

    doSearch(input.value);
  });
}

function renderSearchResult(result) {
  const el = document.createElement("a");
  el.href = result.url;
  el.className = "header-search-result-entry";
  el.innerHTML = `
    <div class='feature-image'>
      <img src='${result.image_portrait_url}' alt='${result.title}' class='header-search-result-entry-image' />
    </div>
    <div class="entry-content">
      <h4>${result.title}</h4>
      <div class='entry-show-type is-${result.show_type}'>${result.show_type} show</div>
      <p>${result.first_booking_date}</p>
    </div>
    <div class="entry-action">
      <div>
        <span class="action-text">View Show</span>
        <span class="fa fa-chevron-right"></span>
      </div>
    </div>

  `;
  return el;
}

export function searchIsOpen() {
  const { headerSearch } = getElements();
  if (!headerSearch) return false;

  return headerSearch.classList.contains("show") ? true : false;
}
