import "./scss/index.scss";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import stickyHeader from "./stickyHeader";
import { navInit } from "./navs";
import { parallaxInit } from "./parallax";
import { swipersInit } from "./swipers";
import { isPageTemplate, isShowSingleTemplate } from "./utils";
import { homeShowsCarousel } from "./homeShowsCarousel";
import { globalSearchInit } from "./globalSearch";
import { showsPageInit } from "./showsPage";

function homePageInit() {
  if (!isPageTemplate("frontpage")) return;

  // shows head
  const showsHead = document.querySelector(".shows-head");
  if (showsHead) showsHead.classList.add("show");

  const homeShowsCarousel = document.querySelector(".home-shows-carousel");

  // show cards
  gsap.from(".show-card", {
    opacity: 0,
    y: 20,
    delay: 0.4,
    duration: 1.5,
    stagger: 0.15,
    onComplete: () => {
      if (homeShowsCarousel) {
        homeShowsCarousel.classList.add("is-ready");
      }
    },
  });
}

function defaultPageInit() {
  if (!isPageTemplate("default") && !isPageTemplate("page-test-show")) return;

  // animations
  gsap.from(".page-title", { opacity: 0, y: 25, duration: 2 });
  gsap.from(".subheading", { opacity: 0, x: -25, duration: 2.5, delay: 0.3 });

  gsap.from(".sub-subheading", {
    opacity: 0,
    x: -25,
    duration: 2.5,
    delay: 0.3,
  });

  gsap.from(".btn-wrap", {
    opacity: 0,
    x: 25,
    duration: 2.5,
    delay: 0.3,
  });

  gsap.from(".feature-content .heading", {
    opacity: 0,
    y: 40,
    duration: 2.8,
    delay: 0.34,
  });
}

function showSingleInit() {
  if (!isShowSingleTemplate()) return;

  gsap.registerPlugin(ScrollTrigger);

  const playHeroIntoTimelines = () => {
    // hero timeline ---------
    const tlHero = gsap.timeline();
    // hero image - landscape

    tlHero.fromTo(
      ".hero-image-wrapper",
      {
        scale: 1.15,
      },
      {
        scale: 1,
        ease: "power4.out",
        duration: 3,
      }
    );

    // title
    tlHero.from(
      ".page-title",
      {
        y: 50,
        opacity: 0,
        delay: 0.2,
        rotate: 3,
        duration: 3,
        ease: "power4.out",
      },
      "-=2.5"
    );
    // subheading
    tlHero.from(
      ".sub-subheading",
      {
        y: 30,
        opacity: 0,
        duration: 2,
        ease: "power4.out",
      },
      "-=2.3"
    );
    // btn
    tlHero.from(
      ".btn-wrap",
      {
        x: 50,
        opacity: 0,
        duration: 2,
        ease: "power4.out",
      },
      "-=2"
    );
    // navbar
    tlHero.from(
      ".site-header-wrap",
      {
        opacity: 0,
        duration: 2,
        ease: "power4.out",
      },
      "-=2.3"
    );

    // intro timeline ----------
    const tlIntro = gsap.timeline({
      scrollTrigger: {
        trigger: ".show-overview",
        start: "top 90%",
      },
    });
    // overview
    tlIntro.from(".show-overview", {
      x: -50,
      opacity: 0,
      duration: 2,
      ease: "power4.out",
    });
    // featured content
    tlIntro.from(
      ".show-overview-content",
      {
        y: 50,
        opacity: 0,
        duration: 2,
        ease: "power4.out",
      },
      "-=1.5"
    );

    // bookings timeline ------------
    const tlBookings = gsap.timeline({
      scrollTrigger: {
        trigger: ".bookings-heading",
        start: "top 80%",
      },
    });
    // bookings title
    tlBookings.from(".bookings-heading", {
      x: -50,
      opacity: 0,
      duration: 2,
      ease: "power4.out",
    });
    // featured content
    tlBookings.from(
      ".booking-entry, .no-bookings-text",
      {
        x: 40,
        opacity: 0,
        duration: 2,
        ease: "power4.out",
        stagger: 0.3,
      },
      "-=1.5"
    );
  };

  var bookingAnchor = window.location.hash.substring(1);
  let animations = bookingAnchor == "bookings" ? false : true;

  if (!animations) {
    document.getElementById("bookings").scrollIntoView();
  } else {
    playHeroIntoTimelines();
  }
}

// init
document.addEventListener("DOMContentLoaded", () => {
  const header = document.getElementById("site-header");
  stickyHeader(header);
  swipersInit();
  navInit();
  parallaxInit();
  homeShowsCarousel();
  showsPageInit();
  homePageInit();
  defaultPageInit();
  showSingleInit();
  globalSearchInit();
});
